import React, { useEffect, useRef, useState } from 'react';
import { useAppData } from '../AppContext';
import gameplayCoinImg from '../images/coin.png';
import coinImg from '../images/icons/coin.png';
import bateryImg from '../images/icons/batery.png';
import teamImg from '../images/teams/teamlogo_1.jpg';
import specialRocketImg from '../images/specials/rocket.png';
import specialBombImg from '../images/specials/bomb.png';
import specialShurikenImg from '../images/specials/shuriken.png';
import "./home.scss";
import dollar_coin from "./dollar_coin.png";
import rocket from "./rocket.png";
import StatBlock from '../Components/StatsMain/StatBlock';
import Navigate from '../Components/NavigateBtn/Navigate';
import Footer from '../Components/Footer/Footer';
import ThanksNiki from '../Components/ThanksNiki/ThanksNiki';
import energy_img from "./enegry.png";
import { Link, useNavigate } from 'react-router-dom';
import { sendCoinsAndEnergy, getUserInfo, USER_ID } from '../http';
import { useTranslation } from 'react-i18next';
import punch1 from "./punch1.png";
import punch2 from "./punch2.png";
import trophy from "./Gold trophy.png";
import Background from './background.png';
import { useLeague } from '../Components/StatsMain/LeagueContext';
import Bronze from './Bronze.png';
import Silver from './Silver.png';
import Gold from './Gold.png';
import Platinum from './Platinum.png';
import Diamond from './Diamond.png';
import Master from './Master.png';
import Grandmaster from './Grandmaster.png';

export const Home = () => {
    const navigate = useNavigate();
    const leagueInfo = useLeague();
    const { t } = useTranslation();

    const coinImage = useRef(null);
    const energyBar = useRef(null);
    const specialBackground = useRef(null);
    const { coins, energy, energyLimit, setEnergy, clickCost, specialMode, total_coins_earned, handleEditCoinsClick, energyRechargeDelay, getUserInfoData, memes, leagues } = useAppData();
    const [interactionActive, setInteractionActive] = useState(false);
    const [username, setUsername] = useState("");
    const [coinsHome, setCoinsHome] = useState(coins);
    const [prevCoins, setPrevCoins] = useState(coins);
    const [currentLeague, setCurrentLeague] = useState(leagues[0]);
    const [energyHome, setEnergyHome] = useState(energy);
    const [prevEnergy, setPrevEnergy] = useState(energy);
    const [isClicked, setIsClicked] = useState(false);
    const coinsHomeRef = useRef(coinsHome);
    const prevCoinsRef = useRef(prevCoins);
    const energyHomeRef = useRef(energyHome);
    const prevEnergyRef = useRef(prevEnergy);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const [currentPunchImage, setCurrentPunchImage] = useState(punch1);

    const leaguesWithLogos = leagues.map((league, index) => {
        let logo;
        switch (index) {
            case 0: logo = Bronze; break;
            case 1: logo = Silver; break;
            case 2: logo = Gold; break;
            case 3: logo = Platinum; break;
            case 4: logo = Diamond; break;
            case 5: logo = Master; break;
            case 6: logo = Grandmaster; break;
            default: logo = Bronze;
        }
        return { ...league, logo };
    });

    useEffect(() => {
        const isFirstTime = localStorage.getItem('isFirstTime');

        if (!isFirstTime) {
            getUserInfoData();
            localStorage.setItem('isFirstTime', 'false');
        }
    }, []);

    useEffect(() => {
        coinsHomeRef.current = coinsHome;
        prevCoinsRef.current = prevCoins;
        energyHomeRef.current = energyHome;
        prevEnergyRef.current = prevEnergy;
    }, [coinsHome, prevCoins, energyHome, prevEnergy]);

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 20);
        setCurrentPunchImage(punch2);
        setTimeout(() => setCurrentPunchImage(punch1), 400);
    };

    const handleTouchStart = (event) => {
        event.preventDefault();
        Array.from(event.touches).forEach(touch => {
            applyTransformation(touch.clientX, touch.clientY);
            earnCoins(clickCost, touch.clientX, touch.clientY);
        });
    };

    function earnCoins(income, clientX, clientY) {
        if (specialMode) {
            let randomFactorOptions = [0.5, 1, 1.5];
            let randomFactor = randomFactorOptions[Math.floor(Math.random() * randomFactorOptions.length)];
            income += specialMode.bonus * randomFactor;
        } else {
            income = Math.min(income, energyHome);
        }

        if (income > 0) {
            handleEditCoinsClick(income);
            setCoinsHome(coinsHome + income);
            showIncome(income, clientX, clientY);
            if (!specialMode) {
                setEnergyHome(energy - income);
                setEnergy(energy - income);
            }
        }
    }

    const specialData = {
        "full_energy": {
            spanImage: `url(${specialRocketImg})`,
            background: 'rgba(255, 150, 26, 1)'
        },
        "shuriken": {
            spanImage: `url(${specialShurikenImg})`,
            background: 'rgba(167, 25, 255, 1)'
        },
        "full_energy": {
            spanImage: `url(${energy_img})`,
            background: 'rgb(18 255 167 / 80%)'
        }
    };

    function showIncome(income, clientX, clientY) {
        const incomeElement = document.createElement('div');
        incomeElement.innerText = '+' + income;
        incomeElement.classList.add('income');
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY + 100}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY - 64}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    }

    useEffect(() => {
        if (energyBar.current) {
            energyBar.current.style.width = `${(energy / energyLimit) * 100}%`;
        }
    }, [energy, energyLimit]);

    useEffect(() => {
        if (specialMode) {
            if (specialMode.mode === "full_energy") {
                setEnergyHome(energyLimit);
            }
            const specialInfo = specialData[specialMode.mode];
            specialBackground.current.style.display = 'block';
            specialBackground.current.style.opacity = 1;
            const specialBackgroundElement = specialBackground.current.querySelector('.special-background');
            const specialSpans = specialBackground.current.querySelectorAll('.special-background span');
            specialBackgroundElement.style.background = `radial-gradient(circle at center bottom, ${specialInfo.background}, rgb(12, 12, 12) 75%)`;
            specialSpans.forEach(span => {
                span.style.backgroundImage = specialInfo.spanImage;
            });
        } else {
            specialBackground.current.style.opacity = 0;
            specialBackground.current.addEventListener('transitionend', function () {
                specialBackground.current.style.display = 'none';
            }, { once: true });
        }
    }, [specialMode]);

    const [clickTimes, setClickTimes] = useState([]);

    const handleInteraction = (event) => {
        event.preventDefault();

        const currentTime = Date.now();
        const newClickTimes = clickTimes.filter(t => currentTime - t < 10000);

        newClickTimes.push(currentTime);
        setClickTimes(newClickTimes);

        if (newClickTimes.length > 200) {
            window.Telegram.WebApp.close();
            return;
        }

        if (clickCount >= 6 || currentTime - lastClickTime < 150) {
            return;
        }

        setLastClickTime(currentTime);
        setClickCount(clickCount + 1);

        if (interactionActive) return;
        setInteractionActive(true);

        let clientX, clientY;
        if (event.type === 'touchstart') {
            Array.from(event.touches).forEach(touch => {
                clientX = touch.clientX;
                clientY = touch.clientY;
                applyTransformation(clientX, clientY);
                earnCoins(clickCost, clientX, clientY);
            });
        } else if (event.type === 'mousedown') {
            clientX = event.clientX;
            clientY = event.clientY;
            applyTransformation(clientX, clientY);
            earnCoins(clickCost, clientX, clientY);
        }

        setTimeout(() => {
            setInteractionActive(false);
        }, 300);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setClickTimes([]);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const onTransitionEnd = () => {
        if (coinImage.current) {
            coinImage.current.style.transform = "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)";
        }
        setInteractionActive(false);
    };

    const applyTransformation = (clientX, clientY) => {
        const containerRect = coinImage.current.getBoundingClientRect();
        const centerX = (clientX - containerRect.left) / containerRect.width * 2 - 1;
        const centerY = (clientY - containerRect.top) / containerRect.height * 2 - 1;
        const rotateX = -centerY * 15;
        const rotateY = centerX * 15;
        coinImage.current.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(0.98, 0.98, 0.98)`;
    };

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
            const webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
            if (webAppUser) {
                setUsername(webAppUser.first_name);
            }
        }
    }, [setUsername]);

    useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            setEnergyHome((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
            setEnergy((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
        }, 1000);
        return () => clearInterval(energyUpdateInterval);
    }, [energyLimit, energyRechargeDelay]);

    useEffect(() => {
        const coinAndEnergySyncInterval = setInterval(() => {
            const difference = coinsHomeRef.current - prevCoinsRef.current;

            sendCoinsAndEnergy(difference, energyHomeRef.current);

            setPrevCoins(coinsHomeRef.current);
        }, 5000);

        return () => clearInterval(coinAndEnergySyncInterval);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            const difference = coinsHomeRef.current - prevCoinsRef.current;
            sendCoinsAndEnergy(difference, energyHomeRef.current);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const findCurrentLeague = () => {
            for (let i = leaguesWithLogos.length - 1; i >= 0; i--) {
                if (leaguesWithLogos[i].coin_limit === "max" || total_coins_earned >= leaguesWithLogos[i].coin_limit) {
                    return leaguesWithLogos[i];
                }
            }
            return leaguesWithLogos[0];
        };

        setCurrentLeague(findCurrentLeague());
    }, [total_coins_earned]);

    return (
        <div style={{
            height: '100vh',
            overflow: 'hidden',
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>

            <div className="container">

                <div className="effect-background" ref={specialBackground}>
                    <div className="special-background">
                        <span style={{ '--i': 16 }}></span>
                        <span style={{ '--i': 11 }}></span>
                        <span style={{ '--i': 19 }}></span>
                        <span style={{ '--i': 13 }}></span>
                        <span style={{ '--i': 15 }}></span>
                        <span style={{ '--i': 20 }}></span>
                        <span style={{ '--i': 12 }}></span>
                        <span style={{ '--i': 17 }}></span>
                    </div>
                </div>

                <div className='column'>

                    <StatBlock />
                    <div className='row_home'>
                        <div className="stats">
                            <img src={dollar_coin} className="stats-coin" alt="Coin icon" />
                            <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                        </div>
                    </div>

                    <div className='leagueContainer' onClick={() => { navigate('/leagues'); }}>
                        {currentLeague ? (
                            <>
                                <img src={currentLeague.logo} className='leagueImg' draggable={false} alt='trophy' />
                                <h2 className='leagueName'>{currentLeague.name}</h2>
                                <button className="tab-button-arrow">›</button>
                            </>
                        ) : (
                            <p>Loading league...</p>
                        )}
                    </div>
                </div>

                <div id="playZone" className="play-zone">
                    <div className="gameplay-coin" onTouchStart={handleTouchStart} ref={coinImage}>
                        <img
                            src={currentPunchImage}
                            className={currentPunchImage === punch2 ? 'speed-up' : ''}
                            alt="Punch"
                            draggable="false"
                            onTouchStart={handleTouchStart}
                            onPointerDown={handleInteraction}
                            onTransitionEnd={onTransitionEnd}
                            onClick={handleClick}
                            style={{
                                transition: 'transform 0.1s ease-out',
                                transform: isClicked ? 'perspective(1000px)' : 'perspective(0px)',
                            }}
                        />
                    </div>
                </div>

                <div className='column'>
                    <div className="energy-wrapper">
                        <div className='row_home'>
                            <img src={energy_img} className="energy-icon" alt="Energy icon" />
                            <div className="energy-counter">
                                <span>{energy}</span>/{energyLimit}
                            </div>
                            {/* <div className="levels">
                                lvl: {leagueInfo.name}/7
                            </div> */}
                        </div>
                        <div className='energy-bar' ref={energyBar}>

                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </div>
    );
};
